.header {
  padding: 20px 0 20px 0;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 24px;
}

.header__title {
  line-height: 1.3em;
}

@media (max-width: 992px) {
  .header__title {
    margin: 0;
  }
}
