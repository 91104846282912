@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

body {
  background-color: #fff;
  color: #363b45;
  font-size: 16px;
  font-family: 'Fira Sans', sans-serif;
}

a {
  text-decoration: none;
  color: #363b45;
  transition: color .3s;
}

a:hover {
  color: #cc00e3;
}

h2 {
  font-weight: 600;
  margin: 30px 0;
}

