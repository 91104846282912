.rating {
  border: 0.2rem solid #ececec;
  border-radius: 8px;
  margin: 24px 0;
}

.rating__image {
  width: 100%;
  height: 100%;
  border-radius: 8px 0 0 8px;
  min-height: 140px;
  object-fit: cover;
}

.rating__preview {
  position: relative;
  display: flex;
  height: 100%;
  line-height: 0;
  flex-direction: column;
}

.rating__place {
  position: absolute;
  left: 10px;
  top: 10px;
}

.rating__rating {
  display: flex;
  flex-direction: column;
}

.rating__name {
  margin-top: 24px;
  font-size: 18px;
  font-weight: 700;
}

.rating__count {
  font-size: 12px;
  color: #878787;
  display: block;
  margin-top: 4px;
}

.rating__cup {
  position: absolute;
  right: 8px;
  top: 8px;
  color: rgb(255, 171, 56);
}

.rating__stars {
  padding-top: 4px;
  margin-left: -3px;
}

.rating__info {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  font-size: 14px;
}

.rating__item {
  font-weight: 400;
  margin-bottom: 12px;
  line-height: 18px;
  display: flex;
}

.rating__item_icon {
  margin-right: 6px;
  position: relative;
  top: -2px;
  color: #cc00e3;
  min-width: 18px;
}

.rating__item_icon_gray {
  color: #6b747d;
  top: -1px;
}

.rating__values {
  font-size: 14px;
  position: relative;
  top: 2px;
  left: 5px;
  color: #198754;
}

.rating__button {
  width: 100%;
  font-size: 14px !important;
  margin-top: 24px;
  padding: 10px 0 !important;
  text-transform: uppercase;
  font-weight: 500 !important;
}

.rating__buttons {
  padding-right: 24px;
}

.rating__button_link {
  outline: 0 !important;
  /*line-height: 18px !important;*/
  color: #878787 !important;
  text-decoration: none !important;
  margin-top: 8px;
}

.rating__button_link:hover {
  text-decoration: underline !important;
}

.return {
  display: flex;
  align-items: center;
  border-top: 1px solid #ececec;
  margin-top: 14px;
  padding-top: 14px;
  margin-bottom: 14px;
  color: #009458;
  font-size: 14px;
}

.return__icon {
  margin-right: 4px;
}

@media (max-width: 1200px) {
  .rating__button {
    display: block;
    margin: 24px 0;
    padding: 14px 0 !important;
  }

  .rating__button_link {
    margin-top: 0;
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
  }

  .rating__buttons {
    padding-right: 24px;
    padding-left: 24px;
  }

  .rating__info {
    padding-left: 24px;
  }

  .rating__image {
    border-radius: 8px 8px 0 0;
  }

  .rating__rating {
    padding-left: 24px;
  }
}

.rating__payment {
  padding-top: 14px;
  padding-bottom: 24px;
}

.rating__payment__title {
  font-weight: 500;
  color: #878787;
}

.rating__payment__icons {
  padding-top: 14px;
}

.rating__payment__icons img {
  height: 30px;
  width: auto;
  margin-right: 4px;
  border-radius: 4px;
}

@media (max-width: 1200px) and (min-width: 768px) {
  .rating__image {
    border-radius: 8px;
    margin: 24px;
  }

  .rating__cup {
    right: 0;
    top: 40px;
  }
}

@media (max-width: 992px) {
  .rating__payment {
    padding-bottom: 4px;
  }

  .rating__info,
  .return,
  .rating__button,
  .rating__button_link {
    font-size: 16px !important;
  }
}
